<template>
  <!-- https://digitelematica.atlassian.net/browse/EUR-571 -->

  <v-container class="card-check pa-0" fluid>
    <h2
      class="py-3 text-uppercase text-center primary"
      v-if="currentStep === 1"
    >
      {{ $t("register.cardCheck.switch-text") }}
    </h2>
    <v-row
      v-if="currentStep === 1"
      justify="center"
      align="center"
      class="tab-switch px-4 px-sm-0"
    >
      <div>
        <v-row justify="center" no-gutters>
          <v-btn-toggle
            v-model="tab"
            color="secondary"
            :light="false"
            mandatory
          >
            <v-btn
              class="mx-4"
              x-large
              value="1"
              :outlined="tab === '2'"
              text
              @click="onTabChange(true)"
              >{{ $t("common.yes") }}</v-btn
            >
            <v-btn
              class="mx-4"
              x-large
              value="2"
              :outlined="tab === '1'"
              text
              @click="onTabChange(false)"
              >{{ $t("common.no") }}</v-btn
            >
          </v-btn-toggle>
        </v-row>
      </div>
    </v-row>

    <!-- ####################################################### -->
    <!-- ########### TAB 1 (utente ha la card) ################# -->
    <!-- ####################################################### -->
    <v-container
      class="tab-card my-10 text-left align-center align-md-start"
      v-show="tab === '1' && currentStep === 1"
    >
      <h2 class="text-uppercase mb-7 text-center text-lg-left">
        {{ $t("register.cardCheck.yes.title") }}
      </h2>
      <v-form ref="cardform" v-model="isCardFormValid">
        <v-row class="d-flex">
          <v-col cols="12" sm="6">
            <!-- #### NUMERO DI CARTA ### -->
            <h4 class="text-uppercase mb-2">
              {{ $t("register.cardCheck.yes.fidelityCard") }}
              <span style="color:red;">*</span>
            </h4>
            <v-text-field
              v-model="registerData.fidelityCard"
              :placeholder="$t('register.cardCheck.yes.fidelityCard')"
              :rules="[requiredRules()]"
              required
              solo
              elevation="0"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <!-- #### C.FISCALE/P. IVA ### -->
            <h4 class="text-uppercase mb-2">
              {{ $t("register.cardCheck.yes.fiscalCode") }}
              <span style="color:red;">*</span>
            </h4>
            <v-text-field
              v-model="registerData.person.fiscalCode"
              :rules="[requiredRules()]"
              :placeholder="$t('register.cardCheck.yes.fiscalCode')"
              required
              solo
              elevation="0"
            />
          </v-col>
        </v-row>
      </v-form>

      <v-row
        no-gutters
        class="d-flex justify-center justify-lg-end mt-10 mt-sm-6"
      >
        <v-btn
          color="secondary"
          x-large
          elevation="0"
          :disabled="!isCardFormValid"
          @click="submitCardData()"
        >
          {{ $t("register.cardCheck.yes.submit") }}
        </v-btn>
        <h5 class="error--text ml-4 font-weight-bold" v-if="error">
          {{ $t("register.cardCheck.yes.error") }}
        </h5>
      </v-row>
    </v-container>
    <!-------------------------------------------------------------------->

    <!-- ####################################################### -->
    <!-- ######### TAB 2 (utente non ha la card) ############### -->
    <!-- ####################################################### -->
    <v-container
      class="tab-card my-10 text-left align-center align-md-start"
      v-show="tab === '2' && currentStep === 1"
    >
      <v-form ref="registerform" v-model="isRegisterFormValid">
        <!-- ######### DATI DELLA SOCIETA' ############### -->
        <v-row>
          <v-col cols="12">
            <h2 class="text-uppercase text-center text-lg-left">
              {{ $t("register.cardCheck.no.title.company") }}
            </h2>
          </v-col>

          <v-col cols="12" sm="6" class="pb-0">
            <!-- ##### NAZIONE (IT, DE, MT) ##### -->
            <!-- al cambio di nazione vengono modificate le regole di validazione per postalcode e p. Iva -->
            <!-- al cambio di nazione viene gestita la visualizzazione del blocco FATTURAZIONE -->
            <!-- al cambio di nazione viene gestita la tipologia dei campi Comune e Provincia -->
            <h4 class="text-uppercase mb-2">
              {{ $t("register.cardCheck.no.country") }}
              <span style="color:red;">*</span>
            </h4>
            <v-select
              outlined
              v-model="registerData.billingAddress.countryId"
              item-text="name"
              item-value="countryId"
              :placeholder="$t('register.cardCheck.no.country')"
              :items="customCountries"
              v-on:change="
                onCountryChange(registerData.billingAddress.countryId)
              "
              required
              :rules="[requiredRules()]"
            />
          </v-col>

          <v-col cols="12" sm="6" class="pb-0" v-if="countryId === 1">
            <!-- ##### REGIONE RIFERIMENTO CONSEGNA (IT) ##### -->
            <!-- alla selezione della regione il form per la nazione ITA viene visualizzato. -->
            <h4 class="text-uppercase mb-2">
              {{ $t("register.cardCheck.no.region") }}
              <span style="color:red;">*</span>
            </h4>
            <v-select
              outlined
              v-model="region"
              item-text="name"
              item-value="name"
              :placeholder="$t('register.cardCheck.no.region')"
              :items="customRegions"
              required
              :rules="[requiredRules()]"
            />
          </v-col>
        </v-row>
        <v-row
          v-if="countryId === 2 || countryId === 3 || countryId === 5 || region"
        >
          <v-col cols="12" sm="6" class="pb-0">
            <!-- ##### TIPO AZIENDA (IT, DE, MT) ##### -->
            <!-- le voci del menu variano in base alla nazione selezionata -->
            <!-- il tipo di azienda influsce sul campo SDI di FATTURAZIONE (IT) -->
            <h4 class="text-uppercase mb-2">
              {{ $t("register.cardCheck.no.companyType") }}
              <span style="color:red;">*</span>
            </h4>
            <v-select
              v-model="registerData.person.personTypeId"
              :placeholder="$t('register.cardCheck.no.companyType')"
              outlined
              :items="filteredCompanyTypes"
              :disabled="!countryId"
              required
              :rules="[requiredRules()]"
              @change="onCompanyTypeChange"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <!-- ##### RAGIONE SOCIALE ##### -->
            <h4 class="text-uppercase mb-2">
              {{ $t("register.cardCheck.no.companyLabel") }}
              <span style="color:red;">*</span>
            </h4>
            <v-text-field
              outlined
              v-model="registerData.person.company"
              :placeholder="$t('register.cardCheck.no.companyLabel')"
              required
              :rules="[requiredRules()]"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <!-- ##### PARTITA IVA ##### -->
            <h4 class="text-uppercase mb-2">
              {{ $t("register.cardCheck.no.vatCode") }}
              <span style="color:red;">*</span>
            </h4>
            <!-- campo con validazione p. Iva per regole IT -->
            <v-text-field
              ref="vat"
              v-if="!countryId || countryId === 1"
              v-model.trim="registerData.person.vatCode"
              :placeholder="$t('register.cardCheck.no.vatCode')"
              :rules="vatCodeRulesNoCardItalia"
              @blur="$refs.registerform.validate()"
              outlined
              :disabled="!countryId"
            />

            <!-- campo con validazione p. Iva per regole DE -->
            <v-text-field
              ref="vat"
              v-if="countryId === 3"
              v-model.trim="registerData.person.vatCode"
              :placeholder="$t('register.cardCheck.no.vatCode')"
              :rules="vatCodeRulesNoCardGermania"
              @blur="$refs.registerform.validate()"
              outlined
              :disabled="!countryId"
            />

            <!-- campo con validazione p. Iva per regole MT -->
            <v-text-field
              ref="vat"
              v-if="countryId === 2"
              v-model.trim="registerData.person.vatCode"
              :placeholder="$t('register.cardCheck.no.vatCode')"
              :rules="vatCodeRulesNoCardMalta"
              @blur="$refs.registerform.validate()"
              outlined
              :disabled="!countryId"
            />

            <!-- campo con validazione p. Iva per regole MT -->
            <v-text-field
              ref="vat"
              v-if="countryId === 5"
              v-model.trim="registerData.person.vatCode"
              :placeholder="$t('register.cardCheck.no.vatCode')"
              :rules="vatCodeRulesNoCardFrancia"
              @blur="$refs.registerform.validate()"
              outlined
              :disabled="!countryId"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <!-- ##### EMAIL ##### -->
            <h4 class="text-uppercase mb-2">
              {{ $t("register.cardCheck.email") }}
              <span style="color:red;">*</span>
            </h4>
            <v-text-field
              v-model="registerData.email"
              :rules="emailRules"
              :placeholder="$t('register.cardCheck.email')"
              required
              outlined
            />
          </v-col>

          <v-col cols="12" sm="6">
            <!-- ##### CELLULARE ##### -->
            <h4 class="text-uppercase mb-2">
              {{ $t("register.cardCheck.no.mobilePhone") }}
              <span style="color:red;">*</span>
            </h4>
            <v-text-field
              v-model="registerData.phone"
              :rules="genericPhoneRules"
              :placeholder="$t('register.cardCheck.no.mobilePhone')"
              outlined
              required
            />
          </v-col>

          <v-col cols="12" sm="6">
            <!-- ##### SETTORE AZIENDA ##### -->
            <h4 class="text-uppercase mb-2">
              {{ $t("register.cardCheck.no.sectorType") }}
              <span style="color:red;">*</span>
            </h4>
            <v-select
              v-model="registerData.person.personInfos[0].value"
              :placeholder="$t('register.cardCheck.no.sectorType')"
              outlined
              :items="filteredSectorTypes"
              :disabled="!countryId"
              required
              :rules="[requiredRules()]"
            />
          </v-col>
        </v-row>

        <div
          v-if="countryId === 2 || countryId === 3 || countryId === 5 || region"
        >
          <v-divider class="mb-10 mt-5" />

          <!-- ######### LEGALE RAPPRESENTANTE ############### -->
          <v-row>
            <v-col cols="12">
              <h2 class="text-uppercase text-center text-lg-left">
                {{ $t("register.cardCheck.no.title.representative") }}
              </h2>
            </v-col>

            <v-col cols="12" sm="4">
              <!-- ##### NOME ##### -->
              <h4 class="text-uppercase mb-2">
                {{ $t("register.cardCheck.no.firstName") }}
                <span style="color:red;">*</span>
              </h4>
              <v-text-field
                v-model="registerData.person.firstName"
                :rules="[requiredRules()]"
                :placeholder="$t('register.cardCheck.no.firstName')"
                outlined
                required
              />
            </v-col>

            <v-col cols="12" sm="4">
              <!-- ##### COGNOME ##### -->
              <h4 class="text-uppercase mb-2">
                {{ $t("register.cardCheck.no.lastName") }}
                <span style="color:red;">*</span>
              </h4>
              <v-text-field
                v-model="registerData.person.lastName"
                :rules="[requiredRules()]"
                :placeholder="$t('register.cardCheck.no.lastName')"
                outlined
                required
              />
            </v-col>

            <v-col cols="12" sm="4">
              <!-- ##### DATA DI NASCITA ##### -->
              <h4 class="text-uppercase mb-2">
                {{ $t("register.cardCheck.no.birthDate") }}
                <span style="color:red;">*</span>
              </h4>
              <v-menu
                ref="menu"
                v-model="birthdateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="birthDate"
                    :label="$t('register.cardCheck.no.birthDate')"
                    :rules="[requiredValue()]"
                    required
                    readonly
                    outlined
                  >
                    <v-icon v-bind="attrs" v-on="on" slot="append">
                      $editcalendar
                    </v-icon>
                  </v-text-field>
                </template>
                <v-date-picker
                  locale="it-IT"
                  ref="picker"
                  v-model="registerData.person.birthDate"
                  min="1910-01-01"
                  first-day-of-week="1"
                  type="date"
                  no-title
                  @input="birthdateMenu = false"
                  :key="componentKey"
                />
              </v-menu>
            </v-col>
          </v-row>

          <v-divider class="mb-10" />

          <!-- ######### SEDE LEGALE ############### -->
          <v-row>
            <v-col cols="12">
              <h2 class="text-uppercase text-center text-lg-left">
                {{ $t("register.cardCheck.no.title.legalAddress") }}
              </h2>
            </v-col>

            <v-col cols="12" sm="6">
              <!-- ##### PROVINCIA ##### -->
              <!-- il tipo di campo cambia in base alla nazione selezionata -->
              <!-- per l'Italia, la scelta della provincia carica l'elenco dei Comuni corrispondenti -->
              <h4 class="text-uppercase mb-2">
                {{ $t("register.cardCheck.no.province") }}
                <span style="color:red;">*</span>
              </h4>
              <!-- Provincia per Germania e Malta (testo libero) -->
              <v-text-field
                ref="billingAddressProvince"
                v-if="
                  !countryId ||
                    countryId === 3 ||
                    countryId === 2 ||
                    countryId === 5
                "
                v-model="registerData.billingAddress.province"
                :rules="[requiredRules()]"
                :placeholder="$t('register.cardCheck.no.province')"
                outlined
                required
                maxlength="2"
                :disabled="!countryId"
              />

              <!-- Provincia per Italia (testo libero con autocompletamento) -->
              <v-autocomplete
                ref="billingAddressProvince"
                v-if="countryId === 1"
                v-model="selectedBillingAddressProvince"
                :placeholder="$t('register.cardCheck.no.province')"
                outlined
                maxlength="2"
                return-object
                :items="provinceList"
                item-text="name"
                item-value="name"
                :disabled="!countryId"
                @change="
                  onProvinceChange(
                    selectedBillingAddressProvince,
                    'billingAddressCitiesList'
                  )
                "
              />
            </v-col>

            <v-col cols="12" sm="6">
              <!-- ##### COMUNE ##### -->
              <!-- il tipo di campo cambia in base alla nazione selezionata -->
              <h4 class="text-uppercase mb-2">
                {{ $t("register.cardCheck.no.city") }}
                <span style="color:red;">*</span>
              </h4>
              <!-- Comune per Germania e Malta (testo libero) -->
              <v-text-field
                ref="billingAddressCity"
                v-if="
                  !countryId ||
                    countryId === 3 ||
                    countryId === 2 ||
                    countryId === 5
                "
                v-model="registerData.billingAddress.city"
                :rules="[requiredRules()]"
                :placeholder="$t('register.cardCheck.no.city')"
                outlined
                required
              />

              <!-- Comune per Italia (testo libero con autocompletamento) -->
              <!-- la selezione del Comune popola l'elenco di selezione dei codici postali corrispondenti -->
              <v-autocomplete
                ref="billingAddressCity"
                v-if="countryId === 1"
                v-model="selectedBillingAddressCity"
                :rules="[requiredRules()]"
                :placeholder="$t('register.cardCheck.no.city')"
                outlined
                :items="billingAddressCitiesList"
                required
                item-text="name"
                item-value="name"
                return-object
                :disabled="!selectedBillingAddressProvince"
                @change="onCityChange(selectedBillingAddressCity, 'billing')"
              />
            </v-col>

            <v-col cols="12" sm="4">
              <!-- ##### INDIRIZZO ##### -->
              <h4 class="text-uppercase mb-2">
                {{ $t("register.cardCheck.no.address") }}
                <span style="color:red;">*</span>
              </h4>
              <v-text-field
                v-model="registerData.billingAddress.address1"
                :rules="[requiredRules()]"
                :placeholder="$t('register.cardCheck.no.address')"
                outlined
                required
              />
            </v-col>

            <v-col cols="12" sm="4">
              <!-- ##### NUMERO CIVICO ##### -->
              <h4 class="text-uppercase mb-2">
                {{ $t("register.cardCheck.no.addressNumber") }}
                <span style="color:red;">*</span>
              </h4>
              <v-text-field
                v-model="registerData.billingAddress.addressNumber"
                :rules="isAddressNumberRule"
                :placeholder="$t('register.cardCheck.no.addressNumber')"
                outlined
                required
              />
            </v-col>

            <v-col cols="12" sm="4">
              <!-- ##### CODICE POSTALE ##### -->
              <!-- il valore accettato varia in base alla nazione selezionata -->
              <h4 class="text-uppercase mb-2">
                {{ $t("register.cardCheck.no.postalCode") }}
                <span style="color:red;">*</span>
              </h4>
              <!-- Codice postale valido in Italia -->

              <v-combobox
                ref="billingAddressPostalCode"
                v-if="!countryId || countryId === 1"
                v-model="registerData.billingAddress.postalcode"
                :rules="[requiredRules()]"
                :placeholder="$t('register.cardCheck.no.postalCode')"
                :items="postalCodeList.billing"
                item-text="name"
                item-value="name"
                append-icon=""
                outlined
                maxlength="5"
                required
                :disabled="!countryId"
              />
              <!-- Codice postale valido in Germania -->
              <v-text-field
                v-if="countryId === 3 || countryId === 5"
                v-model="registerData.billingAddress.postalcode"
                :rules="[requiredRules(), isNumber()]"
                :placeholder="$t('register.cardCheck.no.postalCode')"
                outlined
                maxlength="5"
                required
                :disabled="!countryId"
              />
              <!-- Codice postale valido a Malta -->
              <v-text-field
                v-if="countryId === 2"
                v-model="registerData.billingAddress.postalcode"
                :rules="[requiredRules(), isMaltaPostalCode()]"
                :placeholder="$t('register.cardCheck.no.postalCode')"
                maxlength="5"
                outlined
                required
                :disabled="!countryId"
              />
            </v-col>
          </v-row>

          <v-divider class="mb-10"></v-divider>

          <!-- ######### INDIRIZZO DI CONSEGNA SE DIVERSO DA SEDE LEGALE ############### -->
          <v-row>
            <v-col cols="12">
              <h2 class="text-uppercase text-center text-lg-left">
                {{ $t("register.cardCheck.no.title.deliveryAddress") }}
              </h2>
            </v-col>

            <v-col cols="12" sm="6">
              <!-- ##### PROVINCIA ##### -->
              <!-- il tipo di campo cambia in base alla nazione selezionata -->
              <!-- per l'Italia, la scelta della provincia carica l'elenco dei Comuni corrispondenti -->
              <h4 class="text-uppercase mb-2">
                {{ $t("register.cardCheck.no.province") }}
              </h4>
              <!-- Provincia per Germania e Malta (testo libero) -->
              <v-text-field
                ref="deliveryAddressProvince"
                v-if="
                  !countryId ||
                    countryId === 3 ||
                    countryId === 2 ||
                    countryId === 5
                "
                v-model="registerData.deliveryAddress.province"
                :placeholder="$t('register.cardCheck.no.province')"
                outlined
                maxlength="2"
                :disabled="!countryId"
              />

              <!-- Provincia per Italia (testo libero con autocompletamento) -->
              <v-autocomplete
                ref="deliveryAddressProvince"
                v-if="countryId === 1"
                v-model="selectedDeliveryAddressProvince"
                :placeholder="$t('register.cardCheck.no.province')"
                outlined
                maxlength="2"
                return-object
                :items="provinceList"
                item-text="name"
                item-value="name"
                :disabled="!countryId"
                @change="
                  onProvinceChange(
                    selectedDeliveryAddressProvince,
                    'deliveryAddressCitiesList'
                  )
                "
              />
            </v-col>

            <v-col cols="12" sm="6">
              <!-- ##### COMUNE ##### -->
              <!-- il tipo di campo cambia in base alla nazione selezionata -->
              <h4 class="text-uppercase mb-2">
                {{ $t("register.cardCheck.no.city") }}
              </h4>
              <!-- Comune per Germania e Malta (testo libero) -->
              <v-text-field
                ref="deliveryAddressCity"
                v-if="
                  !countryId ||
                    countryId === 3 ||
                    countryId === 2 ||
                    countryId === 5
                "
                v-model="registerData.deliveryAddress.city"
                :placeholder="$t('register.cardCheck.no.city')"
                outlined
              />

              <!-- Comune per Italia (testo libero con autocompletamento) -->
              <!-- la selezione del Comune popola l'elenco di selezione dei codici postali corrispondenti -->
              <v-autocomplete
                ref="deliveryAddressCity"
                v-if="countryId === 1"
                v-model="selectedDeliveryAddressCity"
                :placeholder="$t('register.cardCheck.no.city')"
                outlined
                :items="deliveryAddressCitiesList"
                item-text="name"
                item-value="name"
                return-object
                :disabled="!selectedDeliveryAddressProvince"
                @change="onCityChange(selectedDeliveryAddressCity, 'delivery')"
              />
            </v-col>

            <v-col cols="12" sm="4">
              <!-- ##### INDIRIZZO ##### -->
              <h4 class="text-uppercase mb-2">
                {{ $t("register.cardCheck.no.address") }}
              </h4>
              <v-text-field
                v-model="registerData.deliveryAddress.address1"
                :placeholder="$t('register.cardCheck.no.address')"
                outlined
              />
            </v-col>

            <v-col cols="12" sm="4">
              <!-- ##### NUMERO CIVICO ##### -->
              <h4 class="text-uppercase mb-2">
                {{ $t("register.cardCheck.no.addressNumber") }}
              </h4>
              <v-text-field
                v-model="registerData.deliveryAddress.addressNumber"
                :placeholder="$t('register.cardCheck.no.addressNumber')"
                outlined
              />
            </v-col>

            <v-col cols="12" sm="4">
              <!-- ##### CODICE POSTALE ##### -->
              <!-- il valore accettato varia in base alla nazione selezionata -->
              <h4 class="text-uppercase mb-2">
                {{ $t("register.cardCheck.no.postalCode") }}
              </h4>
              <!-- Codice postale valido in Italia -->
              <!-- testo con autocompletamento quando viene selezionata la deliveryAddress.city -->
              <v-combobox
                ref="deliveryAddressPostalCode"
                v-if="!countryId || countryId === 1"
                v-model="registerData.deliveryAddress.postalcode"
                :placeholder="$t('register.cardCheck.no.postalCode')"
                :items="postalCodeList.delivery"
                item-text="name"
                item-value="name"
                append-icon=""
                outlined
                maxlength="5"
                :disabled="!countryId"
              />

              <!-- Codice postale valido in Germania -->
              <v-text-field
                v-if="countryId === 3"
                v-model="registerData.deliveryAddress.postalcode"
                :placeholder="$t('register.cardCheck.no.postalCode')"
                :rules="[isNumber()]"
                outlined
                maxlength="5"
                :disabled="!countryId"
              />

              <!-- Codice postale valido a Malta -->
              <v-text-field
                v-if="countryId === 2"
                v-model="registerData.deliveryAddress.postalcode"
                :placeholder="$t('register.cardCheck.no.postalCode')"
                :rules="[isMaltaPostalCode()]"
                maxlength="5"
                outlined
                :disabled="!countryId"
              />
            </v-col>
          </v-row>

          <v-divider class="mb-10" v-if="countryId === 1" />

          <!-- ######### FATTURAZIONE ############### -->
          <!-- form visibile solo se la nazione selezionata è l'Italia -->
          <v-row v-if="countryId === 1">
            <v-col cols="12">
              <h2 class="text-uppercase text-center text-lg-left">
                {{ $t("register.cardCheck.no.title.billing") }}
              </h2>
              <h4 class="text-uppercase text-center text-lg-left">
                {{ $t("register.cardCheck.no.subtitle.billing") }}
              </h4>
            </v-col>

            <v-col cols="12" sm="6">
              <!-- SDI -->
              <!-- numero massimo di caratteri variabile in base al tipo di compagnia selezionata -->
              <h4 class="text-uppercase mb-2">
                {{ $t("register.cardCheck.no.sdiCode") }}
                <span style="color:red;">*</span>
              </h4>
              <!-- SDI per Azienda -->
              <v-text-field
                ref="sdiCode"
                v-if="registerData.person.personTypeId === 2"
                v-model="registerData.person.vatSdiCode"
                :placeholder="$t('register.cardCheck.no.sdiCode')"
                outlined
                :rules="sdiCodeLengthPerson"
              />

              <!-- SDI per Pubblica Amministrazione -->
              <v-text-field
                v-else
                ref="sdiCode"
                v-model="registerData.person.vatSdiCode"
                :placeholder="$t('register.cardCheck.no.sdiCode')"
                outlined
                :rules="sdiCodeLengthPA"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <!-- email PEC -->
              <h4 class="text-uppercase mb-2">
                {{ $t("register.cardCheck.no.pec") }}
                <span style="color:red;">*</span>
              </h4>
              <v-text-field
                v-model="registerData.person.emailCertified"
                :placeholder="$t('register.cardCheck.no.pec')"
                :rules="emailRules"
                outlined
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
              v-if="registerData.person.personTypeId === 4"
              class="pt-0 pb-5"
            >
              <!-- Split Payment (solo per Pubblica Amministrazione) -->
              <v-checkbox dense v-model="registerData.person.splitPayment">
                <template v-slot:label>
                  <h4
                    class="grey--text text--darken-2 text-uppercase"
                    v-html="$t('register.cardCheck.no.splitPayment')"
                  ></h4> </template
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-divider />
        </div>
      </v-form>

      <div
        v-if="countryId === 2 || countryId === 3 || countryId === 5 || region"
      >
        <!-- ######### INFORMATIVA PRIVACY ############### -->
        <div class="privacy mt-3">
          <h4 class="text-uppercase mb-2">
            <a @click.stop.prevent="goToPrivacy">{{
              $t("register.cardCheck.yes.privacyLink")
            }}</a>
          </h4>
          <i18n path="register.cardCheck.yes.privacyPre" tag="span">
            <template v-slot:link>
              <a
                role="button"
                class="go-to-privacy"
                @click.stop.prevent="goToPrivacy"
                @mousedown.stop
                >{{ $t("register.cardCheck.yes.privacyLink") }}</a
              >
            </template>
          </i18n>
          <!-- <v-radio-group v-model="checked">
            <div>
              <v-col cols="12" sm="3">
                <v-radio
                  :value="true"
                  :label="$t('register.cardCheck.yes.accept')"
                ></v-radio>
              </v-col>
              <v-col cols="12" sm="3">
                <v-radio
                  :value="false"
                  :label="$t('register.cardCheck.yes.notAccept')"
                  ><h2 class="text-uppercase"></h2
                ></v-radio>
              </v-col>
            </div>
          </v-radio-group> -->
          <span>{{ $t("register.cardCheck.yes.privacyPost") }}</span>
        </div>

        <v-row
          no-gutters
          class="d-flex justify-center justify-lg-end mt-10 mt-sm-6"
        >
          <v-btn
            color="primary"
            x-large
            :disabled="!isRegisterFormValid"
            @click="submitRegistrationForm()"
          >
            {{ $t("register.cardCheck.no.submit") }}
          </v-btn>
        </v-row>
      </div>
    </v-container>

    <!-- ** STEP 2 *** -->
    <CreateOnlineAccount
      @prev="backToFirstStep"
      v-if="currentStep === 2"
      :registerData="registerData"
    />
  </v-container>
</template>
<style lang="scss">
.tab-card {
  //background-color: $secondary !important;
  border: 1px solid $secondary;
  color: var(--v-default-base);
}
.card-check {
  .tab-switch {
    height: 100px;
    background-color: $white;
    .v-btn-toggle {
      background: transparent;
      button {
        border-radius: $border-radius-root;
        span {
          color: $primary;
        }
      }
      .v-btn--outlined {
        border-width: 2px;
        &.v-item--active {
          border: 2px solid $text-color !important;
        }
      }
    }
  }
  .tab-card {
    .v-form {
      width: 100%;
    }
    h5 {
      &.error--text {
        line-height: 33px !important;
      }
    }
  }

  .v-input--checkbox {
    .v-label {
      font-size: 15px !important;
    }
  }
}
</style>
<script>
import {
  requiredValue,
  isIvaItalia,
  isIvaMalta,
  isIvaGermania,
  isIvaFrancia,
  isEmail,
  isMobilePhone,
  isGenericPhone,
  isNumber,
  isMaltaPostalCode,
  isPasswordMinimumLength,
  isSDICode,
  isAddressNumber
} from "@/validator/validationRules";
import EurospinCustomService from "@/service/eurospinCustomService";
import CreateOnlineAccount from "@/components/registration/CreateOnlineAccount.vue";
export default {
  data() {
    return {
      tab: "1",
      currentStep: 1,
      componentKey: 0,
      isCardFormValid: false,
      isRegisterFormValid: false,
      birthdateMenu: false,
      customCountries: global.config.customCountries,
      customRegions: global.config.customRegions,
      requiredRules: requiredValue,
      requiredValue: requiredValue,
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      mobilePhoneRules: [
        requiredValue("Digitare il numero di cellulare"),
        isMobilePhone()
      ],
      genericPhoneRules: [
        requiredValue("Digitare il numero di telefono"),
        isGenericPhone()
      ],
      isEmail: isEmail,
      emailRulesNotRequired: [isEmail()],
      passwordRules: [
        requiredValue("Digitare la password"),
        isPasswordMinimumLength()
      ],
      sdiRules: [isSDICode()],
      isNumber: isNumber,
      isMaltaPostalCode: isMaltaPostalCode,
      // checked: false,
      registerData: {
        billingAddress: { countryId: 1 },
        deliveryAddress: {},
        defaultStoreAddress: {},
        person: {
          personTypeId: 2,
          personInfos: [{ value: "", personInfoTypeId: 7 }]
        }
      },
      region: null,
      provinceList: [],
      deliveryAddressCitiesList: [],
      billingAddressCitiesList: [],
      selectedDeliveryAddressProvince: null,
      selectedBillingAddressProvince: null,
      selectedDeliveryAddressCity: null,
      selectedBillingAddressCity: null,
      postalCodeList: {
        billing: [],
        delivery: []
      },
      response: {},
      cfIvaRules: [requiredValue(), isIvaMalta()],
      isAddressNumberRule: [requiredValue("Civico o SNC"), isAddressNumber()],
      vatCodeRulesNoCardItalia: [
        v =>
          !!(v || this.registerData.person.fiscalCode) ||
          "Partita Iva obbligatoria",
        isIvaItalia()
      ],
      vatCodeRulesNoCardGermania: [
        v =>
          !!(v || this.registerData.person.fiscalCode) ||
          "Partita Iva obbligatoria",
        isIvaGermania()
      ],
      vatCodeRulesNoCardFrancia: [
        v =>
          !!(v || this.registerData.person.fiscalCode) ||
          "Partita Iva obbligatoria",
        isIvaFrancia()
      ],
      vatCodeRulesNoCardMalta: [
        v =>
          !!(v || this.registerData.person.fiscalCode) ||
          "Partita Iva obbligatoria",
        isIvaMalta()
      ],
      sdiCodeLengthPerson: [
        v =>
          (v && v.length == 7) ||
          (this.registerData.person.vatSdiCode &&
            this.registerData.person.vatSdiCode.length == 7) ||
          "Codice SDI non valido",
        isSDICode()
      ],
      sdiCodeLengthPA: [
        v =>
          (v && v.length == 6) ||
          (this.registerData.person.vatSdiCode &&
            this.registerData.person.vatSdiCode.length == 6) ||
          "Codice SDI non valido",
        isSDICode()
      ],
      error: null
    };
  },
  components: { CreateOnlineAccount },
  computed: {
    countryId() {
      return this.registerData.billingAddress.countryId;
    },
    birthDate: {
      get: function() {
        if (this.registerData.person.birthDate) {
          return this.$dayjs(this.registerData.person.birthDate).format(
            "DD/MM/YYYY"
          );
        } else {
          return null;
        }
      },
      set: function(value) {
        if (value) {
          this.registerData.person.birthDate = this.$dayjs(
            value,
            "DD/MM/YYYY"
          ).format("YYYY-MM-DD");
        } else {
          this.registerData.person.birthDate = null;
        }
      }
    },
    validBillingValues() {
      return !!(
        this.registerData.person.vatSdiCode ||
        (this.registerData.person.emailCertified &&
          this.isEmail(this.registerData.person.emailCertified))
      );
    },
    filteredSectorTypes() {
      return global.config.sectorTypes;
    },
    filteredCompanyTypes() {
      let items = [];
      if (this.countryId) {
        for (var i = 0; i < global.config.companyTypes.length; i++) {
          if (
            global.config.companyTypes[i].countries.includes(this.countryId)
          ) {
            items.push(global.config.companyTypes[i]);
          }
        }
        return items;
      }
      return global.config.companyTypes;
    }
  },

  methods: {
    backToFirstStep() {
      this.currentStep = 1;
    },
    onTabChange(value) {
      this.$emit("showStepper", value);
    },
    goToPrivacy() {
      if (this.$platform_is_cordova) {
        window.cordova.InAppBrowser.open("/page/privacy-policy", "_system");
      } else {
        window.open("/page/privacy-policy", "_blank");
      }
    },
    getProvinces(provinceId, homeDeliveryOnly) {
      let _this = this;
      EurospinCustomService.getProvinces(provinceId, homeDeliveryOnly).then(
        results => {
          _this.provinceList = results;
        }
      );
    },
    onCompanyTypeChange() {
      if (this.$refs.sdiCode) {
        this.$refs.sdiCode.reset();
        this.isRegisterFormValid = false;
      }
    },
    async onCityChange(city, itemsArray) {
      let _this = this;
      if (city) {
        let postalCodes = await EurospinCustomService.getPostalCodes(
          city.itemId,
          false
        );
        if (postalCodes.length) {
          if (postalCodes[0].itemId === -1) {
            postalCodes.shift();
          }
          _this.postalCodeList[itemsArray] = postalCodes;
          if (postalCodes.length === 1) {
            if (itemsArray === "delivery") {
              _this.registerData.deliveryAddress.postalcode =
                postalCodes[0].name;
            }
            if (itemsArray === "billing") {
              _this.registerData.billingAddress.postalcode =
                postalCodes[0].name;
            }
          }
        }
      }
      return [];
    },
    onProvinceChange(province, cityList) {
      if (province) {
        let _this = this;
        EurospinCustomService.getCities(province.itemId, false).then(
          results => {
            _this[cityList] = results;
            if (cityList === "deliveryAddressCitiesList") {
              _this.$refs.deliveryAddressCity.reset();
            }
            if (cityList === "billingAddressCitiesList") {
              _this.$refs.billingAddressCity.reset();
            }
          }
        );
      }
    },
    onCountryChange(selectedCountry) {
      this.$refs.registerform.reset();
      this.registerData.billingAddress.countryId = selectedCountry;
      this.registerData.person.personTypeId = 2;
      this.selectedDeliveryAddressProvince = null;
      this.selectedBillingAddressProvince = null;
      this.componentKey++;
      // this.checked = false;
      this.onCompanyTypeChange();
      this.$nextTick(() => {
        this.registerData.person.personTypeId = this.filteredCompanyTypes[0].value;
      });
    },
    /**
     * registrazione e conseguente invio di link via email
     * per utenti con carta
     */
    async submitCardData() {
      try {
        this.error = null;
        this.loading = true;
        if (this.$refs.cardform.validate()) {
          // let res = await EurospinCustomService.checkCard(this.registerData);
          // console.log(res);
          this.loading = false;
          let res = { response: { status: 0 } };
          if (res.response && res.response.status === 0) {
            this.registerData.email = "gianluca.cannizzaro@digitelematica.it";
            this.currentStep = 2;
          } else {
            if (res.errors) {
              global.EventBus.$emit("error", {
                error: res.errors[0].error,
                message: res.errors[0].error
              });
              this.error = true;
            }
          }
        }
      } catch (response) {
        this.response = response;
      } finally {
        this.loading = false;
      }
    },

    /**
     * richiesta di registrazione per gli utenti sprovvisti di carta
     */
    async submitRegistrationForm() {
      try {
        this.error = null;
        this.loading = true;

        if (this.$refs.registerform.validate()) {
          this.$router.push({
            name: "RegistrationCompleted"
          });
          console.log(this);
        }
        if (this.registerData.billingAddress.countryId === 1) {
          if (
            this.selectedBillingAddressProvince &&
            this.selectedBillingAddressProvince.name
          ) {
            this.registerData.billingAddress.province = this.selectedBillingAddressProvince.name;
          }
          if (
            this.selectedDeliveryAddressProvince &&
            this.selectedDeliveryAddressProvince.name
          ) {
            this.registerData.deliveryAddress.province = this.selectedDeliveryAddressProvince.name;
          }
          if (
            this.selectedBillingAddressCity &&
            this.selectedBillingAddressCity.name
          ) {
            this.registerData.billingAddress.city = this.selectedBillingAddressCity.name;
          }
          if (
            this.selectedDeliveryAddressCity &&
            this.selectedDeliveryAddressCity.name
          ) {
            this.registerData.deliveryAddress.city = this.selectedDeliveryAddressCity.name;
          }
          if (this.registerData.deliveryAddress.name) {
            this.registerData.deliveryAddress.postalcode = this.registerData.deliveryAddress.postalcode.name;
          }
          if (this.registerData.billingAddress.postalcode.name) {
            this.registerData.billingAddress.postalcode = this.registerData.billingAddress.postalcode.name;
          }
        }

        if (this.registerData.billingAddress.countryId === 5) {
          this.region = "Sardegna";
        }

        this.registerData.defaultStoreAddress.addressId =
          this.region && this.region === "Sardegna" ? 180001 : 1337740;
        // let res = await EurospinCustomService.registerPerson(this.registerData);
        // console.log(res);
        let res = { response: { status: 0 } };
        this.loading = false;
        if (res.response && res.response.status === 0) {
          this.$router.push({
            name: "RegistrationCompleted"
          });
        } else {
          if (res.errors) {
            global.EventBus.$emit("error", {
              error: res.errors[0].error,
              message: res.errors[0].error
            });
          }
        }
      } catch (response) {
        this.response = response;
      } finally {
        this.loading = false;
      }
    }
  },
  async created() {
    // Italy provinces (provinceId: 1)
    await this.getProvinces(1, false);
  }
};
</script>
